import React from 'react';
import { Link } from 'react-router-dom'
import Logo from '../../Assets/Images/finduku-logo.svg'
import facebook_logo from '../../Assets/Images/facebook.svg';
import linkedin_logo from '../../Assets/Images/linkedin.svg';
import instagram_logo from '../../Assets/Images/instagram.svg';
import twitter_logo from '../../Assets/Images/twitter.svg';
import '../../Styles/CompStyles/Footer.css';

function Footer() {
     return (
          <>
               <div className="footer">
                    <div className="footer__page-container">
                         <div className="footer__topbar-section">
                              <div className="footer__corporate-wrapper">
                                   <img src={Logo} alt="Finduku Logo" className="footer__corporate-logo" />
                              </div>
                              <div className="footer__links-wrapper">
                                   <p className="footer__links-label">Products</p>
                                   <ul className="footer__links-container">
                                        <li className="footer__link-item">
                                             <Link to="/features" className="footer__link">Features</Link>
                                        </li>
                                        <li className="footer__link-item">
                                             <Link to="/rewards" className="footer__link">Rewards</Link>
                                        </li>
                                        <li className="footer__link-item">
                                             <Link to="/pricing" className="footer__link">Pricing</Link>
                                        </li>
                                        <li className="footer__link-item">
                                             <Link to="/request-demo" className="footer__link">Product Demo</Link>
                                        </li>
                                   </ul>
                              </div>
                              <div className="footer__links-wrapper">
                                   <p className="footer__links-label">Solutions</p>
                                   <ul className="footer__links-container">
                                        <li className="footer__link-item">
                                             <Link to="/online-payments" className="footer__link">Online Payments</Link>
                                        </li>
                                        <li className="footer__link-item">
                                             <Link to="/subscriptions" className="footer__link">Subscriptions</Link>
                                        </li>
                                        <li className="footer__link-item">
                                             <Link to="/in-store-payments" className="footer__link">In-store Payments</Link>
                                        </li>
                                   </ul>
                              </div>
                              <div className="footer__links-wrapper">
                                   <p className="footer__links-label">Resources</p>
                                   <ul className="footer__links-container">
                                        <li className="footer__link-item">
                                             <Link to="/merchants" className="footer__link">Merchants</Link>
                                        </li>
                                        <li className="footer__link-item">
                                             <Link to="/blogs" className="footer__link">Blogs</Link>
                                        </li>
                                        <li className="footer__link-item">
                                             <Link to="/help-center" className="footer__link">Help Center</Link>
                                        </li>
                                   </ul>
                              </div>
                              <div className="footer__links-wrapper">
                                   <p className="footer__links-label">About</p>
                                   <ul className="footer__links-container">
                                        <li className="footer__link-item">
                                             <Link to="/about-us" className="footer__link">About Us</Link>
                                        </li>
                                        <li className="footer__link-item">
                                             <Link to="/press" className="footer__link">Press</Link>
                                        </li>
                                        <li className="footer__link-item">
                                             <Link to="/careers" className="footer__link">Careers</Link>
                                        </li>
                                        <li className="footer__link-item">
                                             <Link to="/contact-support" className="footer__link">Contact Us</Link>
                                        </li>
                                   </ul>

                              </div>
                         </div>
                         <div className="footer__bottomBar-section">
                              <div className="footer__copyright-wrapper">
                                   <p className="footer__copyright">
                                             &#64; {new Date().getFullYear()} Finduku Philippines, Inc.
                                        </p>
                                   </div>
                              <div className="footer__policy-wrapper">
                                   <ul className="footer__policy-link-container">
                                        <li className="footer__policy-item">
                                             <Link to="/privacy-policy" className="footer__policy-link">Privacy Policy</Link>
                                        </li>
                                        <li className="footer__policy-item">
                                             <Link to="/terms-and-conditions" className="footer__policy-link">Terms & Conditions</Link>
                                        </li>
                                   </ul>
                              </div>
                              <div className="footer__socials-wrapper">
                                   <a href="https://www.facebook.com/finduku" target="_blank" rel="noreferrer">
                                        <img src={facebook_logo} alt="Facebook Logo" className="footer__socials-link" />
                                   </a>
                                   <a href="https://www.linkedin.com/company/finduku" target="_blank" rel="noreferrer">
                                        <img src={linkedin_logo} alt="Linkedin Logo" className="footer__socials-link" />
                                   </a>
                                   <a href="https://www.instagram.com/finduku" target="_blank" rel="noreferrer">
                                        <img src={instagram_logo} alt="Instagram Logo" className="footer__socials-link" />
                                   </a>
                                   <a href="https://www.twitter.com/finduku" target="_blank" rel="noreferrer">
                                        <img src={twitter_logo} alt="X Logo" className="footer__socials-link" />
                                   </a>
                                   
                              </div>
                         </div>
                    </div>
               </div>
          </>
     )
}

export default Footer