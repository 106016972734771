import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from '../../API/axios';
import '../../Styles/PageStyles/Waitlist/Waitlist.css';

function Waitlist() {

    const SIGNUP_WAITLIST_ENDPOINT = '/apps/signup-waitlist/'

    const [waitlistError, setWaitlistError] = useState(false)
    const [waitlistErrorMsg, setWaitlistErrorMsg]  = useState(null)    
    const [waitlistErrorAction, setWaitlistErrorAction]  = useState(null)
    const [waitlistErrorCode, setWaitlistErrorCode]  = useState(null)
    const [isSubmittingForm, setIsSubmittingForm] = useState(false)
    const [dataSubmitted, setDataSubmitted] = useState(false)
    
    const [waitlistData, setWaitlistData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        referral_code: '',
        policy_agreement: '',
        sms_agreement: '',
    })

    const validationSchema = Yup.object({
        first_name: Yup.string()
        .required('Please provide your first name.'),

        last_name: Yup.string()
        .required('Please provide your last name.'),

        email: Yup.string()
        .email('Email address format is invalid.')
        .required('Please provide your email address.'),

        phone_number: Yup.string()
        .required('Please provide your phone number.')
        .matches(/^\d{9,11}$/, {message: "Phone number must be 11 digits.", excludeEmptyString: false}),
    })

    const updateWaitlistData = (updatedData) => {
        setWaitlistData(updatedData);
    };

    const submitForm = async () => {
        setIsSubmittingForm(true)
        try {
            const res = await axios.post(
                SIGNUP_WAITLIST_ENDPOINT,
                JSON.stringify({ 
                    first_name: waitlistData.first_name, 
                    last_name: waitlistData.last_name,
                    email: waitlistData.email,
                    phone_number: waitlistData.phone_number,
                    referral_code: waitlistData.referral_code,
                }),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                }
            );
            if (res.data.success){
                setDataSubmitted(true);
            } else {
                console.error('Invalid data received', res.data)
                setWaitlistError(true);
                setWaitlistErrorMsg("Oops! We encountered an error.")
                setWaitlistErrorAction("Please try again or contact support.")
                setWaitlistErrorCode("ERR_FINDUKU_2000")                
            }
        } catch (error) {
            console.log('Something went wrong when submitting form', error)
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setWaitlistErrorMsg("Oops! We encountered an error.")
                        setWaitlistErrorAction("Please try again or contact support.")
                        setWaitlistErrorCode("ERR_FINDUKU_3000") 
                        break;
                    case 401:
                        setWaitlistErrorMsg("Oops! We encountered an error.")
                        setWaitlistErrorAction("Please try again or contact support.")
                        setWaitlistErrorCode("ERR_FINDUKU_4000") 
                        break;
                    case 403:
                        setWaitlistErrorMsg("Oops! We encountered an error.")
                        setWaitlistErrorAction("Please try again or contact support.")
                        setWaitlistErrorCode("ERR_FINDUKU_5000")  
                        break;
                    case 404:
                        setWaitlistErrorMsg("Oops! We encountered an error.")
                        setWaitlistErrorAction("Please try again or contact support.")
                        setWaitlistErrorCode("ERR_FINDUKU_6000") 
                        break;
                    case 500:
                        setWaitlistErrorMsg("Oops! We encountered an error.")
                        setWaitlistErrorAction("Please try again or contact support.")
                        setWaitlistErrorCode("ERR_FINDUKU_7000")  
                        break;
                    default:
                        setWaitlistErrorMsg("Oops! We encountered an error.")
                        setWaitlistErrorAction("Please try again or contact support.")
                        setWaitlistErrorCode("ERR_FINDUKU_1000")                    
                }
            } else {
                setWaitlistErrorMsg("Oops! We encountered an error.")
                setWaitlistErrorAction("Please try again or contact support.")
                setWaitlistErrorCode("ERR_FINDUKU_8000")  
            } 
                setWaitlistError(true)           
        } finally {
                setIsSubmittingForm(false)
        }
    }


    return (
        <div className="waitlist">
            <div className="waitlist__page-container">
                <div className="waitlist__left-section">
                    <p>This is the image section</p>
                </div>
                <div className="waitlist__right-section">
                    <div className="waitlist__right-section-wrapper">
                        <div className="waitlist__form-wrapper">
                            <Formik
                                initialValues={waitlistData}
                                onSubmit={submitForm}
                                validationSchema={validationSchema}
                            >
                                {({ handleChange, handleBlur, values, errors, touched }) => (
                                    <Form>
                                        <div className="waitlist__form">
                                            <div className="waitlist__form-heading">
                                                <div className="waitlist__heading-text">
                                                    Join the Waitlist
                                                </div>
                                                <div className="waitlist__subheading-text">
                                                    Be one of the first to get Finduku mobile application and discover the next generation of payments.
                                                </div>
                                            </div>
                                            <div className="waitlist__name-input-wrapper">
                                                <div className="waitlist__name-input">
                                                    <input 
                                                        type="text" 
                                                        className={`waitlist__input ${errors.first_name && touched.first_name ? "waitlist__input-error" : null}`}
                                                        name="policy"
                                                        value={values.first_name}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            updateWaitlistData({...waitlistData, first_name: e.target.value});
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder="First Name"
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div className="waitlist__name-input">
                                                    <input 
                                                        type="text" 
                                                        className={`waitlist__input ${errors.last_name && touched.last_name ? "waitlist__input-error" : null}`}
                                                        name="last_name"
                                                        value={values.last_name}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            updateWaitlistData({...waitlistData, last_name: e.target.value});
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder="Last Name"
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                            <div className="waitlist__email-input-wrapper">
                                                <input 
                                                    type="text" 
                                                    className={`waitlist__input ${errors.email && touched.email ? "waitlist__input-error" : null}`}
                                                    name="email"
                                                    value={values.email}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        updateWaitlistData({...waitlistData, email: e.target.value});
                                                    }}
                                                    onBlur={handleBlur}
                                                    placeholder="Email"
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div className="waitlist__phone-number-input-wrapper">
                                                <input 
                                                    type="text" 
                                                    className={`waitlist__input ${errors.email && touched.email ? "waitlist__input-error" : null}`}
                                                    name="phone_number"
                                                    value={values.phone_number}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        updateWaitlistData({...waitlistData, phone_number: e.target.value});
                                                    }}
                                                    onBlur={handleBlur}
                                                    placeholder="Phone Number"
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div className="waitlist__referral-code-input-wrapper">
                                                <input 
                                                    type="text" 
                                                    className={`waitlist__input ${errors.referral_code && touched.referral_code ? "waitlist__input-error" : null}`}
                                                    name="referral_code"
                                                    value={values.referral_code}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        updateWaitlistData({...waitlistData, referral_code: e.target.value});
                                                    }}
                                                    onBlur={handleBlur}
                                                    placeholder="Referral Code (Optional)"
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div className="waitlist__agreement-input-wrapper">
                                                <div className="waitlist__policy-input">
                                                    <input 
                                                        type="checkbox" 
                                                        className={`waitlist__checkbox-input ${errors.policy_agreement && touched.policy_agreement ? "waitlist__input-error" : null}`}
                                                        name="policy_agreement"
                                                        value={values.policy_agreement}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            updateWaitlistData({...waitlistData, policy_agreement: e.target.value});
                                                        }}
                                                        onBlur={handleBlur}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div className="waitlist__policy-text">
                                                    <p className="waitlist__policy-text-content">
                                                        I have read and agree to Finduku's Terms and Conditions and Privacy Policy.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="waitlist__agreement-input-wrapper">
                                                <div className="waitlist__policy-input">
                                                    <input 
                                                        type="checkbox" 
                                                        className={`waitlist__checkbox-input ${errors.policy_agreement && touched.policy_agreement ? "waitlist__input-error" : null}`}
                                                        name="policy_agreement"
                                                        value={values.policy_agreement}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            updateWaitlistData({...waitlistData, policy_agreement: e.target.value});
                                                        }}
                                                        onBlur={handleBlur}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div className="waitlist__policy-text">
                                                    <p className="waitlist__policy-text-content">
                                                        I agree to receive automated SMS messages and emails from Finduku for updates about my account.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="waitlist__button-wrapper">
                                                <button 
                                                    type="submit"
                                                    className="waitlist__submit-button"
                                                >
                                                    Continue
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Waitlist