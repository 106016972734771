import React from 'react';
import '../../Styles/PageStyles/Shops/Shops.css';

function Shops() {
    return (
        <div className="shops">
            <div className="shops__page-container">
                <p>This is the Shops Page.</p>
            </div>
        </div>
    )
}

export default Shops