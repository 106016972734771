import React from 'react'
import scan_qr from '../../../Assets/Images/scan-qr.svg'
import instant_payout from '../../../Assets/Images/instant-payout.svg'
import payment_auth from '../../../Assets/Images/payment-auth.svg'

import { BsCheckLg  } from "react-icons/bs";



function Features() {
    return (
        <>
            <div className="features-section">
                <div className="features-section__container">
                    <div className="features-section__background-wrapper">
                        <div className="features-section__header">
                            <p className="features-section__header-title">Accept bank payments in a new way</p>
                            <p className="features-section__header-subtitle">Receive bank payments without relying on traditional bank transfers</p>
                        </div>
                        <div className="features-section__feature-wrapper">
                            <div className="features-section__feature-text-wrapper">
                                <p className="features-section__feature-heading">Reduce processing costs up to 50% vs cards and e-wallets</p>
                                <p className="features-section__feature-subheading">
                                    Simplify customer payments by letting them pay by bank initiated via scanning your In-store QR or using payment links.
                                </p>
                                <div className="features-section__feature-item-wrapper">
                                    <div className="features-section__feature-item">
                                        <div className="features-section__feature-icon-container">
                                            <BsCheckLg className="features-section__feature-icon"/>
                                        </div>
                                        <p className="features-section__feature-text">
                                            Speed up each transaction by letting your customers 
                                            pay by simply scanning your store's QR code
                                        </p>
                                    </div>
                                    <div className="features-section__feature-item">
                                        <div className="features-section__feature-icon-container">
                                            <BsCheckLg className="features-section__feature-icon"/>
                                        </div>
                                        <p className="features-section__feature-text">
                                            You may also send a payment link to your customers 
                                            via SMS, email, messenger, whatsapp, viber and more
                                        </p>
                                    </div>
                                    <div className="features-section__feature-item">
                                        <div className="features-section__feature-icon-container">
                                            <BsCheckLg className="features-section__feature-icon"/>
                                        </div>
                                        <p className="features-section__feature-text">
                                            For online merchants, your customers can simply select the Pay by Bank option
                                            at the checkout page
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="features-section__feature-image-wrapper">
                                <img src={scan_qr} alt="Problem Illustration" />
                            </div>
                        </div>
                        <div className="features-section__feature-wrapper">
                            <div className="features-section__feature-image-wrapper">
                                <img src={payment_auth} alt="Problem Illustration" />
                            </div>
                            <div className="features-section__feature-text-wrapper">
                                <p className="features-section__feature-heading">
                                    Enhance customer experience with frictionless bank payments
                                </p>
                                <p className="features-section__feature-subheading">
                                    Simplify customer payments by letting them pay by bank initiated via scanning your In-store QR or using payment links.
                                </p>
                                <div className="features-section__feature-item-wrapper">
                                    <div className="features-section__feature-item">
                                        <div className="features-section__feature-icon-container">
                                            <BsCheckLg className="features-section__feature-icon"/>
                                        </div>
                                        <p className="features-section__feature-text">
                                            Empower your customers with seamless payments 
                                            by enabling them to use just their mobile banking apps
                                        </p>
                                    </div>
                                    <div className="features-section__feature-item">
                                        <div className="features-section__feature-icon-container">
                                            <BsCheckLg className="features-section__feature-icon"/>
                                        </div>
                                        <p className="features-section__feature-text">
                                            Give your customers more payment options by allowing 
                                            them to pay using either their deposit or credit accounts
                                        </p>
                                    </div>
                                    <div className="features-section__feature-item">
                                        <div className="features-section__feature-icon-container">
                                            <BsCheckLg className="features-section__feature-icon"/>
                                        </div>
                                        <p className="features-section__feature-text">
                                            Help your customers save money on transaction fees with Pay by Bank 
                                            payment that doesn't charge fees
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="features-section__feature-wrapper">
                            <div className="features-section__feature-text-wrapper">
                                <p className="features-section__feature-heading">
                                    Track your business growth with a centralized dashboard
                                </p>
                                <p className="features-section__feature-subheading">
                                    Simplify customer payments by letting them pay by bank initiated via scanning your In-store QR or using payment links.
                                </p>
                                <div className="features-section__feature-item-wrapper">
                                    <div className="features-section__feature-item">
                                        <div className="features-section__feature-icon-container">
                                            <BsCheckLg className="features-section__feature-icon"/>
                                        </div>
                                        <p className="features-section__feature-text">
                                            Save up to 80% on transaction fees with Pay by Bank, 
                                            reducing your payment processing costs significantly
                                        </p>
                                    </div>
                                    <div className="features-section__feature-item">
                                        <div className="features-section__feature-icon-container">
                                            <BsCheckLg className="features-section__feature-icon"/>
                                        </div>
                                        <p className="features-section__feature-text">
                                            Gain valuable insights into your business by tracking  
                                            growth with real-time reconciliation of transactions
                                        </p>
                                    </div>
                                    <div className="features-section__feature-item">
                                        <div className="features-section__feature-icon-container">
                                            <BsCheckLg className="features-section__feature-icon"/>
                                        </div>
                                        <p className="features-section__feature-text">
                                            Instant settlement by receiving funds from customers 
                                            almost immediately after the transaction is completed
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="features-section__feature-image-wrapper">
                                <img src={instant_payout} alt="Problem Illustration" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Features