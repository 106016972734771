import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { BsList, BsXLg  } from "react-icons/bs";
import Logo from '../Assets/Images/finduku-logo.svg';

function Navbar() {
    const [showMenu, setShowMenu] = useState(false);

    document.addEventListener('DOMContentLoaded', (event) => {
        const menuItems = document.querySelectorAll('.navbar__menu-item');
    
        menuItems.forEach(item => {
            item.addEventListener('click', function(event) {
                event.stopPropagation(); // Prevent the click from immediately propagating to the document
                clearActive(); // Clear active class from all items
                this.classList.add('active'); // Add 'active' to the clicked item
            });
        });
    
        document.addEventListener('click', clearActive);
    
        function clearActive() {
            menuItems.forEach(item => {
                item.classList.remove('active');
            });
        }
    });

    return (
        <>
            <div className="navbar">
                <div className="navbar__container">
                    <div className="navbar__mobile-wrapper">
                        {/* THIS IS THE LOGO SECTION */}
                        <NavLink to="/" className="navbar__logo-wrapper">
                            <img src={Logo} alt="Finduku Logo"/>
                        </NavLink>
                        <div className="navbar__mobile-menu-wrapper">
                            {showMenu ? (
                                <BsXLg  className="navbar__mobile-menu-icon" onClick={() => {setShowMenu(!showMenu)}}/>
                            ) : (
                                <BsList className="navbar__mobile-menu-icon" onClick={() => {setShowMenu(!showMenu)}}/>
                            )}
                        </div>
                    </div>

                    {/* THIS IS THE MENU SECTION */}
                    <div className={`navbar__menulinks-wrapper ${showMenu ? 'showMenuLinksWrapper' : ''}`}>
                        <ul className="navbar__menu-wrapper">
                            <li className="navbar__menu-item active">
                                <NavLink 
                                    to="/pay-online" 
                                    className={({ isActive }) =>
                                        isActive ? "navbar__menu-links-active" : "navbar__menu-links"
                                    }
                                >
                                    Pay Online
                                </NavLink>
                            </li>
                            <li className="navbar__menu-item active">
                                <NavLink 
                                    to="/pay-in-store" 
                                    className={({ isActive }) =>
                                        isActive ? "navbar__menu-links-active" : "navbar__menu-links"
                                    }
                                >
                                    Pay In Store
                                </NavLink>
                            </li>
                        </ul>
                        <ul className="navbar__navlinks-wrapper">
                            <li className="navbar__navlinks-item active">
                                <NavLink to="/contact-sales" className="navbar__navlink-business">For Business</NavLink>
                            </li>
                            <li className="navbar__navlinks-item active">
                                <NavLink to="/waitlist" className="navbar__navlink-waitlist">Join the Waitlist</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar