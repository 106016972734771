import React from 'react'
import { Routes, Route } from 'react-router-dom'
import './App.css'
import Main from './Pages/Main';
import Home from './Pages/Home/Home';
import ContactSales from './Pages/Home/ContactSales';
import ContactUs from './Pages/Company/Contact';
import AboutUs from './Pages/Company/About';
import Careers from './Pages/Company/Careers';
import Shops from './Pages/Shops/Shops';
import PayOnline from './Pages/PayOnline/PayOnline';
import PayInStore from './Pages/PayInStore/PayInStore';
import PrivacyPolicy from './Pages/Legal/PrivacyPolicy';
import TermsOfUse from './Pages/Legal/TermsOfUse';
import Waitlist from './Pages/Waitlist/Waitlist';

function App() {
    return(
        <div className="App">
            <Routes>
                <Route path="/" element={<Main />} >
                    <Route index element={<Home />} />
                    <Route path="/shops" element={<Shops />} />
                    <Route path="/contact-sales" element={<ContactSales />} />
                    <Route path="/pay-in-store" element={<PayInStore />} />
                    <Route path="/pay-online" element={<PayOnline />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/careers" element={<Careers />} />
                    <Route path="/contact-support" element={<ContactUs />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-and-conditions" element={<TermsOfUse />} />
                    <Route path="/waitlist" element={<Waitlist />} />
                </Route>
            </Routes>
        </div>
    )
}

export default App