import React from 'react';
import { useNavigate } from 'react-router-dom'
import '../../Styles/PageStyles/Company/About.css';


function AboutUs() {
     const navigate = useNavigate();

     const Route2CareersPage = () => {
          navigate('/careers')
     }

     return (
          <>
               <div className="aboutUs">
                    <div className="aboutUs__page-container">
                         <div className="aboutUs__hero-section">
                              <div className="aboutUs__hero-section-container">
                                   <div className="aboutUs__hero-section-heading">
                                        <p className="aboutUs__hero-heading-text">Hi, <span>Meet Finduku.</span></p>
                                        <p className="aboutUs__hero-subheading-text">
                                             As the global leader in Bank Payments, 
                                             we are on a mission to make Pay by Bank Payments a new standard, 
                                             a more attractive alternative to credit and debit cards.
                                        </p>
                                   </div>
                                   <div className="aboutUs__hero-headline-btn-wrapper">
                                        <button 
                                             type="button"
                                             className="aboutUs__hero-headline-btn"
                                        >
                                             Learn more about who we are
                                        </button>
                                   </div>
                              </div>
                         </div>
                         
                         <div className="aboutUs__story-section">
                              <div className="aboutUs__story-section-container">
                                   <div className="aboutUs__story-section-heading-wrapper">
                                        <p className="aboutUs__story-heading">Our story.</p>
                                        <p className="aboutUs__story-subheading">
                                             <span>Our story is your story.</span>
                                             You’re looking for access to options.<br></br>
                                             You want the means for important purchases. 
                                             And you need the power to fund what matters now. 
                                             We want the same thing.
                                        </p>
                                   </div>
                                   <div className="about__top-story-wrapper">
                                        <div className="about__top-story-image-wrapper">
                                             <p className="about__top-story-image-text">
                                                  Add image on this section.
                                             </p>
                                        </div>
                                        <div className="about__top-story-text-wrapper">
                                             <p className="about__top-story-text-heading">The start of things.</p>
                                             <p className="about__top-story-text-body-top">
                                                  Finduku was born from a struggle faced by its founders, Arvyn and Gwenn. 
                                                  Despite steady incomes, they couldn’t get approved for credit cards due to insufficient credit history. 
                                                  Navigating the traditional credit system, they saw its limitations — a system heavily dependent on established credit scores 
                                                  that leaves those without credit profiles, like them, struggling to access even basic credit and lending products. 
                                                  While some providers do lend to people without credit histories, the high-interest fees often make it hard for borrowers to manage and grow financially.
                                             </p>
                                             <p className="about__top-story-text-body-bottom">
                                                  Determined to change this, Arvyn and Gwenn envisioned Finduku as a way to open financial doors for people at all stages of their credit journey. 
                                                  With a focus on creating a cardless payment network, they developed Finduku to provide users with a fresh start — a platform 
                                                  designed to make credit accessible and practical for those ready to build and grow financially on their own terms.
                                             </p>
                                        </div>
                                   </div>
                                   <div className="about__bottom-story-wrapper">
                                        <div className="about__bottom-story-text-wrapper">
                                             <p className="about__bottom-story-text-heading">What's in a name?</p>
                                             <p className="about__bottom-story-text-body-top">
                                                  Finduku is a unique name that carries the essence of the company's mission and origins. 
                                                  The "Fin" in Finduku stands for "financial," reflecting its mission to bring accessible financial solutions to everyone. 
                                                  "Duku" is inspired by “the eleventh child,” a meaningful reference to the company’s founding on the 11th of January 2023.
                                             </p>
                                             <p className="about__bottom-story-text-body-bottom">
                                                  We pronounce Finduku as "fin-DOO-koo," though we welcome other pronunciations. 
                                                  Headquartered in Davao City in Region XI of the Philippines, 
                                                  Finduku is proud to be the first fintech company built in the region. 
                                                  While "Finduku" is a made-up word, it embodies the principles of its founders to fostering financial ease and opportunity 
                                                  — starting from their own community and extending outward.
                                             </p>
                                        </div>
                                        <div className="about__bottom-story-image-wrapper">
                                             <p className="about__bottom-story-image-text">
                                                  Add image on this section.
                                             </p>
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <div className="aboutUs__mission-section">
                              <div className="aboutUs__mission-section-container">
                                   <div className="aboutUs__mission-text-wrapper">
                                        <p className="aboutUs__mission-headline">Our mission</p>
                                        <p className="aboutUs__mission-statement">
                                             We're building the next generation of payments.
                                        </p>
                                   </div>
                                   <div className="aboutUs__profile-wrapper">
                                        <p className="aboutUs__profile-heading">About Us</p>
                                        <p className="aboutUs__profile-content-one">
                                             Finduku® is a financial technology startup based in the Philippines, 
                                             empowering users with accessible and flexible credit lines through a mobile application. 
                                             We operate our own payment network, enabling seamless, cardless transactions for credit-powered 
                                             payments across online and in-store purchases.
                                        </p>
                                        <p className="aboutUs__profile-content-one">
                                             With Finduku, users enjoy a variety of rewards and benefits, including cashback, 
                                             travel perks, and shopping protections, making every purchase more rewarding. 
                                             Our credit lines, accessible directly through our app, eliminate the need for physical cards 
                                             and are tailored to support employees, freelancers, and SMEs.
                                        </p>
                                        <p className="aboutUs__profile-content-one">
                                             Finduku is committed to reshaping credit access in Southeast Asia, starting with our launch in the Philippines. 
                                             By offering a faster, more efficient way to manage finances, build credit history, and open new possibilities, 
                                             we’re redefining how people access and use credit.
                                        </p>
                                   </div>
                              </div>
                         </div>

                         <div className="aboutUs__career-section">
                              <div className="aboutUs__career-section-container">
                                   <div className="aboutUs__career-section-wrapper">
                                        <div className="aboutUs__career-text-wrapper">
                                             <p className="aboutUs__career-text">Let's do great things together. Join our team.</p>
                                        </div>
                                        <div className="aboutUs__career-button-wrapper">
                                             <button 
                                                  type="button"
                                                  className="aboutUs__career-button"
                                                  onClick={Route2CareersPage}
                                             >
                                                  See all open positions
                                             </button>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     )
}

export default AboutUs