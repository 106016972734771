import React from 'react';
import HeroImage from '../../../Assets/Images/myheroimage.svg';


function Hero() {
    return (
        <>
            <div className="hero-section">
                <div className="hero-section__page-container">
                    <div className="hero-section__text-wrapper">
                    <div className="hero-section__heading">
                        <p className="hero-section__heading-title">Your <span>payments,</span></p>
                        <p className="hero-section__heading-title">on your terms.</p>
                    </div>
                    <div className="hero-section__subheading">
                        <p className="hero-section__subheading-text">
                            Use Finduku to pay in full, over time, later or with your rewards,<br></br>right at the checkout whether online or in stores
                            — all from the convenience of your phone.
                        </p>
                    </div>
                    <div className="hero-section__button-wrapper">
                        <button 
                            type="button"
                            className="hero-section__works-btn" 
                        >
                            See how it works
                        </button>
                    </div>
                    </div>
                    <div className="hero-section__image-wrapper">
                    <img src={HeroImage} alt="Hero" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero