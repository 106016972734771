import React from 'react'

function PrivacyPolicy() {
     return (
          <>
               <div className="privacy-policy">
                    <p>This is the Privacy Policy Page</p>
               </div>
          </>
     )
}

export default PrivacyPolicy