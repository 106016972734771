import React from 'react';
import Dukupay from '../../Assets/Images/dukupay_logo.svg';
import Hero from '../../Assets/Images/pay_in_store_hero_image.jpg';
import Tap2PayImage from '../../Assets/Images/tap-to-pay-image.jpg';
import Scan2PayImage from '../../Assets/Images/scan-to-pay-image.jpg';
import '../../Styles/PageStyles/PayInStore/PayInStore.css';

function PayInStore() {
    return(
        <div className="pay-in-store">
            <div className="pay-in-store__page-container">
                <div className="pay-in-store__hero-section-container">
                    <div className="pay-in-store__hero-text-wrapper">
                        <div className="pay-in-store__hero-logo-wrapper">
                            <img src={Dukupay} alt="Finduku Logo" className="pay-in-store__hero-logo"/>
                        </div>
                        <div className="pay-in-store__hero-heading-wrapper">
                            <p className="pay-in-store__hero-heading">Tap or scan to pay <br></br>for your daily essentials</p>
                        </div>
                        <div className="pay-in-store__hero-subheading-wrapper">
                            <p className="pay-in-store__hero-subheading">Pay using your phone — just tap or scan and wait for confirmation.</p>
                        </div>
                    </div>
                    <div className="pay-in-store__hero-image-wrapper">
                        <img src={Hero} alt="Hero" className="pay-in-store__hero-image"/>
                    </div>
                </div>
                <div className="pay-in-store__tap2pay-container">
                    <div className="pay-in-store__tap2pay-text-wrapper">
                        <div className="pay-in-store__tap2pay-topline-wrapper">
                            <p className="pay-in-store__tap2pay-topline">TAP TO PAY</p>
                            <div className="pay-in-store__tap2pay-topline-coming-soon">Coming Soon</div>
                        </div>
                        <div className="pay-in-store__tap2pay-heading-wrapper">
                            <p className="pay-in-store__tap2pay-heading">
                                <span>Pay at stores</span> instantly <br></br>by tapping your phone
                            </p>
                        </div>
                        <div className="pay-in-store__tap2pay-subheading-wrapper">
                            <p className="pay-in-store__tap2pay-subheading">
                                Hold your phone near the payment terminal and wait for the checkmark. 
                                Pay in full with your bank accounts, pay later with your credit lines or use your rewards.
                            </p>
                        </div>
                    </div>
                    <div className="pay-in-store__tap2pay-image-wrapper">
                        <img src={Tap2PayImage} alt="Tap to Pay at Stores" className="pay-in-store__tap2pay-image"/>
                    </div>
                </div>
                <div className="pay-in-store__scan2pay-container">
                    <div className="pay-in-store__scan2pay-image-wrapper">
                        <img src={Scan2PayImage} alt="Scan to Pay at Stores" className="pay-in-store__scan2pay-image"/>
                    </div>
                    <div className="pay-in-store__scan2pay-text-wrapper">
                        <div className="pay-in-store__scan2pay-topline-wrapper">
                            <p className="pay-in-store__scan2pay-topline">SCAN TO PAY</p>
                        </div>
                        <div className="pay-in-store__scan2pay-heading-wrapper">
                            <p className="pay-in-store__scan2pay-heading">
                                <span>Scan QR</span> to pay quickly <br></br>and keep things moving
                            </p>
                        </div>
                        <div className="pay-in-store__scan2pay-subheading-wrapper">
                            <p className="pay-in-store__scan2pay-subheading">
                                Hold your phone near the payment terminal and wait for the checkmark. 
                                Pay in full with your bank accounts, pay later with your credit lines or use your rewards.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="pay-in-store__how2pay-container">
                    <div className="pay-in-store__how2pay-heading-wrapper">
                        <div className="pay-in-store__how2pay-topline-wrapper">
                            <p className="pay-in-store__how2pay-topline">HOW TO PAY WITH DUKUPAY</p>
                        </div>
                        <div className="pay-in-store__how2pay-title-wrapper">
                            <p className="pay-in-store__how2pay-title">Pay faster, easier and safer</p>
                        </div>
                        <div className="pay-in-store__how2pay-subtitle-wrapper">
                            <p className="pay-in-store__how2pay-subtitle">
                                Experience seamless checkout at stores with Dukupay. 
                                Secure payments are just a tap or scan of your phone, making every purchase fast and smooth.
                            </p>
                        </div>
                    </div>
                    <div className="pay-in-store__how2pay-steps-wrapper">
                        <div className="pay-in-store__how2pay-steps-image-wrapper">
                            <div className="pay-in-store__how2pay-steps-presentation">
                                <p>Add content here.</p>
                            </div>
                        </div>
                        <div className="pay-in-store__how2pay-steps-text-wrapper">
                            <div className="pay-in-store__how2pay-steps-text">
                                <p className="pay-in-store__how2pay-steps-title">Pay in a few steps</p>
                            </div>
                            <div className="pay-in-store__how2pay-steps-content">
                                <p className="pay-in-store__how2pay-steps">Unlock your phone and open up the Finduku app with your passcode or face ID.</p>
                                <p className="pay-in-store__how2pay-steps">Select your payment method then hold your phone near the payment terminal.</p>
                                <p className="pay-in-store__how2pay-steps">Tap or scan QR code, wait for a green check mark to confirm the payment.</p>
                                <p className="pay-in-store__how2pay-steps">You can pay in full, later or over time, choose your preferred payment term.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PayInStore