import React from 'react'

function TermsOfUse() {
     return (
          <>
               <div className="terms-of-use">
                    <p>This is the Terms of Use page.</p>
               </div>
          </>
     )
}

export default TermsOfUse