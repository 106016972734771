import React from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";
import '../../Styles/PageStyles/Home/PageLoader.css';

function PageLoader() {
     return (
          <>
               <div className="contact-sales__loader-wrapper">
                    <ScaleLoader
                         height={20}
                         width={5}
                         color="#294254" 
                         loading="true"
                         speedMultiplier={0.80} 
                         aria-label="Loading Spinner" 
                         data-testid="loader"
                    />  
                    <p className="contact-sales__loader-text">
                         Submitting Form
                    </p>
               </div>
          </>
     )
}

export default PageLoader