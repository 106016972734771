import React  from 'react'
import '../../Styles/PageStyles/Home/Home.css';
import Hero from './Homepages/Hero';
import Features from './Homepages/Features';
import Customers from './Homepages/Customers';
import Whitelist from './Homepages/Whitelist';



function Home () {

  return (
    <>
      <div className="homepage">
        <div className="homepage__page-container">
          <Hero />
          <Features />
          <Customers />
          <Whitelist />
        </div>
      </div>
    </>
  )
}

export default Home