import React from 'react'
import '../../../Styles/PageStyles/Home/Customers.css';
import one_off_purchase from '../../../Assets/Images/one-off-purchase.svg'
import recurring_payment from '../../../Assets/Images/recurring-payment.svg'


function Customers() {
  return (
    <>
        <div className="customers-section">
          <div className="section-container">
            <div className="header">
              <p className="title">Simplify your pay by bank payments</p>
              <p className="subtitle">
                A complete payment solution for one-off and recurring transactions
              </p>
            </div>
            <div className="content-area">
              <div className="content-item">
                <p className="caption-title">One-off Purchases</p>
                <div className="image-placeholder">
                  <img src={one_off_purchase} alt="One-off Purchases" />
                </div>
                <p className="caption-details">
                  Easily accept one-time bank payments from your customers. 
                  Customers can make payments in just 3 easy steps using their 
                  preferred online or mobile banking apps.
                </p>
              </div>
              <div className="content-item">
                <p className="caption-title">Recurring Payments</p>
                <div className="image-placeholder">
                  <img src={recurring_payment} alt="self-employed" />
                </div>
                <p className="caption-details">
                  Setup recurring payments tailored to you and your customers. 
                  We support both fixed and per-period pricing, 
                  making it ideal for subscriptions, utilities, invoices and more.
                </p>
              </div>
      
            </div>
          
          </div>
        </div>
    </>
  )
}

export default Customers