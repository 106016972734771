import React from 'react';
import '../Styles/PageStyles/Main.css';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from '../Components/Footer/Footer';

function Main() {
     return (
          <>
               <div className="main">
                    <div className="main__container">
                         <div className="main__header">
                              <Navbar />
                         </div>
                         <div className="main__body">
                              <Outlet />
                         </div>
                         <div className="main__footer">
                              <Footer />
                         </div>
                    </div>
               </div>
          </>
     )
}

export default Main