import React from 'react';
import Dukupay from '../../Assets/Images/dukupay_logo.svg';
import Hero from '../../Assets/Images/pay_online_hero_image.jpg';
import Link2Pay from '../../Assets/Images/link_to_pay_illustration.svg';
import '../../Styles/PageStyles/PayOnline/PayOnline.css';

function PayOnline() {
     return (
          <div className="pay-online">
               <div className="pay-online__page-container">
                    <div className="pay-online__hero-section-container">
                         <div className="pay-online__hero-text-wrapper">
                              <div className="pay-online__hero-logo-wrapper">
                                   <img src={Dukupay} alt="Finduku Logo" className="pay-online__hero-logo"/>
                              </div>
                              <div className="pay-online__hero-heading-wrapper">
                                   <p className="pay-online__hero-heading">Pay with just a click<br></br>for your online purchases</p>
                              </div>
                              <div className="pay-online__hero-subheading-wrapper">
                                   <p className="pay-online__hero-subheading">Pay online your way in fewer steps — payments made simpler than ever.</p>
                              </div>
                         </div>
                         <div className="pay-online__hero-image-wrapper">
                              <img src={Hero} alt="Hero" className="pay-online__hero-image"/>
                         </div>
                    </div>
                    <div className="pay-online__link-section-container">
                         <div className="pay-online__link-text-wrapper">
                              <div className="pay-online__link-topline-wrapper">
                                   <p className="pay-online__link-topline">LINK TO PAY</p>
                              </div>
                              <div className="pay-online__link-heading-wrapper">
                                   <p className="pay-online__link-heading">
                                        <span>Faster checkout</span> starts with linked accounts
                                   </p>
                              </div>
                              <div className="pay-online__link-subheading-wrapper">
                                   <p className="pay-online__link-subheading">
                                        Linking your bank accounts as payment methods allows for quicker and easier transactions, 
                                        as you will not need to log in each time you make a payment.
                                   </p>
                              </div>
                         </div>
                         <div className="pay-online__link-image-wrapper">
                              <img src={Link2Pay} alt="Link to Pay Illustration" className="pay-online__link-image"/>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default PayOnline