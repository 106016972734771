import React, { useState } from 'react';
import '../../Styles/PageStyles/Home/ContactSales.css';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from '../../API/axios';
import { useNavigate } from 'react-router-dom';
import { BsCheckCircle } from "react-icons/bs";
import { BsFillExclamationTriangleFill, BsEmojiFrown } from "react-icons/bs";
import UBPLogo from '../../Assets/Images/unionbank_logo.svg';
import RCBCLogo from '../../Assets/Images/rcbc_logo.svg';
import PageLoader from './PageLoader';


// API ENDPOINTS
const CONTACT_SALES_ENDPOINT = '/apps/contact-sales/'

function ContactSales() {
     const navigate = useNavigate()

     const [salesData, setSalesData] = useState({
          first_name: '',
          last_name: '',
          email: '',
          phone_number: '',
          business_name: '',
     })

     const updateSalesData = (updatedData) => {
          setSalesData(updatedData);
     };

     const [dataSubmitted, setDataSubmitted] = useState(false)
     const [contactSalesError, setContactSalesError] = useState(false)
     const [contactSalesErrorMsg, setContactSalesErrorMsg]  = useState(null)    
     const [contactSalesErrorAction, setContactSalesErrorAction]  = useState(null)
     const [contactSalesErrorCode, setContactSalesErrorCode]  = useState(null)
     const [isSubmittingForm, setIsSubmittingForm] = useState(false)

     const validationSchema = Yup.object({
          first_name: Yup.string()
          .required('Please provide your first name.'),

          last_name: Yup.string()
          .required('Please provide your last name.'),

          email: Yup.string()
          .email('Email address format is invalid.')
          .required('Please provide your email address.'),

          phone_number: Yup.string()
          .required('Please provide your phone number.')
          .matches(/^\d{9,11}$/, {message: "Phone number must be 9-11 digits.", excludeEmptyString: false}),

          business_name: Yup.string()
          .required('Please provide your business name.'),
     })

     const submitForm = async () => {
          setIsSubmittingForm(true)
          try {
               const res = await axios.post(
                    CONTACT_SALES_ENDPOINT,
                    JSON.stringify({ 
                         first_name: salesData.first_name, 
                         last_name: salesData.last_name,
                         email: salesData.email,
                         phone_number: salesData.phone_number,
                         business_name: salesData.business_name,
                    }),
                    {
                         headers: {
                              'Accept': 'application/json',
                              'Content-Type': 'application/json',
                         }
                    }
               );
               if (res.data.success){
                    setDataSubmitted(true);
               } else {
                    console.error('Invalid data received', res.data)
                    setContactSalesError(true);
                    setContactSalesErrorMsg("Oops! We encountered an error.")
                    setContactSalesErrorAction("Please try again or contact support.")
                    setContactSalesErrorCode("ERR_FINDUKU_2000")                
               }
          } catch (error) {
               console.log('Something went wrong when submitting form', error)
               if (error.response) {
                    switch (error.response.status) {
                         case 400:
                              setContactSalesErrorMsg("Oops! We encountered an error.")
                              setContactSalesErrorAction("Please try again or contact support.")
                              setContactSalesErrorCode("ERR_FINDUKU_3000") 
                              break;
                         case 401:
                              setContactSalesErrorMsg("Oops! We encountered an error.")
                              setContactSalesErrorAction("Please try again or contact support.")
                              setContactSalesErrorCode("ERR_FINDUKU_4000") 
                              break;
                         case 403:
                              setContactSalesErrorMsg("Oops! We encountered an error.")
                              setContactSalesErrorAction("Please try again or contact support.")
                              setContactSalesErrorCode("ERR_FINDUKU_5000")  
                              break;
                         case 404:
                              setContactSalesErrorMsg("Oops! We encountered an error.")
                              setContactSalesErrorAction("Please try again or contact support.")
                              setContactSalesErrorCode("ERR_FINDUKU_6000") 
                              break;
                         case 500:
                              setContactSalesErrorMsg("Oops! We encountered an error.")
                              setContactSalesErrorAction("Please try again or contact support.")
                              setContactSalesErrorCode("ERR_FINDUKU_7000")  
                              break;
                         default:
                              setContactSalesErrorMsg("Oops! We encountered an error.")
                              setContactSalesErrorAction("Please try again or contact support.")
                              setContactSalesErrorCode("ERR_FINDUKU_1000")                    
                    }
               } else {
                    setContactSalesErrorMsg("Oops! We encountered an error.")
                    setContactSalesErrorAction("Please try again or contact support.")
                    setContactSalesErrorCode("ERR_FINDUKU_8000")  
               } 
               setContactSalesError(true)           
          } finally {
               setIsSubmittingForm(false)
          }
     }

     // Navigates to Contact Support Page
     const RouteToContactSupport = () => {
          navigate('/contact-support')
     }

     // Navigates to Home Page
     const RouteToHomepage = () => {
          navigate('/')
     }

     return (
          <>
               <div className="contact-sales">             
                    {dataSubmitted ? (
                         <div className="contact-sales__success-msg-container">
                              <div className="contact-sales__success-msg-header">
                                   <BsCheckCircle className="contact-sales__success-msg-icon"/>
                                   <p className="contact-sales__success-msg-heading">We'll be in touch</p>
                              </div>
                              <div className="contact-sales__success-msg-wrapper">
                                   <p className="contact-sales__success-msg">
                                        Thank you for your interest in Finduku. 
                                        Keep an eye on your email
                                   </p>
                                   <p className="contact-sales__success-msg">
                                        — a member of our team 
                                        will be in touch shortly.
                                   </p>
                              </div>
                              <div className="contact-sales__success-msg-wrapper-mobile">
                                   <p className="contact-sales__success-msg-mobile">
                                        Thank you for your interest in Finduku. 
                                        Keep an eye on your email — a member of our team 
                                        will be in touch shortly.
                                   </p>
                              </div>
                              <div className="contact-sales__success-msg-button-wrapper">
                                   <button 
                                        type="button"
                                        className="contact-sales__success-msg-button"
                                        onClick={RouteToHomepage}
                                   >
                                        Back to Home
                                   </button>
                              </div>
                         </div>
                    ) : (
                         <>
                              {contactSalesError ? (
                                   <div className="contact-sales__error-message">
                                        <div className="contact-sales__error-message-container">
                                             <BsEmojiFrown  className="contact-sales__error-message-icon"/>
                                             <p className="contact-sales__error-msg">{contactSalesErrorMsg}</p>
                                             <p className="contact-sales__error-action">{contactSalesErrorAction}</p>
                                             <p className="contact-sales__error-code">{contactSalesErrorCode}</p>
                                        </div>
                                   </div>
                              ) : (
                                   <>
                                        <div className="contact-sales__sales-container">
                                             <div className="contact-sales__sales-form-wrapper">
                                                  <div className="contact-sales__text-container">
                                                       <div className="contact-sales__heading-wrapper">
                                                            <p className="contact-sales__heading">Contact Sales</p>
                                                            <p className="contact-sales__subheading">
                                                                 Our product experts will reach out to demo our product, 
                                                                 discuss your business needs, and answer questions.
                                                            </p>
                                                       </div>
                                                       <div className="contact-sales__partners-wrapper">
                                                            <div className="contact-sales__partners-heading-wrapper">
                                                                 <p className="contact-sales__partners-heading">Our supported banks:</p>
                                                            </div>
                                                            <div className="contact-sales__partners-banks">
                                                                 <div className="contact-sales__partners-banks-group1">
                                                                      <div className="contact-sales__bank-wrapper">
                                                                           <img src={UBPLogo} alt="Unionbank Logo" className="contact-sales__bank-logo" />
                                                                      </div>
                                                                      <div className="contact-sales__bank-wrapper">
                                                                           <img src={RCBCLogo} alt="RCBC Logo" className="contact-sales__bank-logo" />
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <div className="contact-sales__form-container">
                                                       <Formik
                                                            initialValues={salesData}
                                                            onSubmit={submitForm}
                                                            validationSchema={validationSchema}
                                                       >
                                                            {({ handleChange, handleBlur, values, errors, touched }) => (
                                                                 <Form>
                                                                      <div className="contact-sales__form">
                                                                           <div className="contact-sales__form-input-flex">
                                                                                <div className="contact-sales__form-input-wrapper">
                                                                                     <p className="contact-sales__input-label">FIRST NAME</p>
                                                                                     <input 
                                                                                          type="text" 
                                                                                          className={`contact-sales__input ${errors.first_name && touched.first_name ? "contact-sales__input-error" : null}`}
                                                                                          name="first_name"
                                                                                          value={values.first_name}
                                                                                          onChange={(e) => {
                                                                                               handleChange(e);
                                                                                               updateSalesData({...salesData, first_name: e.target.value});
                                                                                          }}
                                                                                          onBlur={handleBlur}
                                                                                          placeholder="Maria"
                                                                                          autoComplete="off"
                                                                                     />
                                                                                     {errors.first_name && touched.first_name ? (
                                                                                          <p className="contact-sales__input-error-message">
                                                                                               <BsFillExclamationTriangleFill className="contact-sales__input-error-icon" />
                                                                                               {errors.first_name}
                                                                                          </p>
                                                                                     ): null}
                                                                                </div>
                                                                                <div className="contact-sales__form-input-wrapper">
                                                                                     <p className="contact-sales__input-label">LAST NAME</p>
                                                                                     <input 
                                                                                          type="text" 
                                                                                          className={`contact-sales__input ${errors.last_name && touched.last_name ? "contact-sales__input-error" : null}`}
                                                                                          name="last_name"
                                                                                          value={values.last_name}
                                                                                          onChange={(e) => {
                                                                                               handleChange(e);
                                                                                               updateSalesData({...salesData, last_name: e.target.value});
                                                                                          }}
                                                                                          onBlur={handleBlur}
                                                                                          placeholder="Dela Cruz"
                                                                                          autoComplete="off"
                                                                                     />
                                                                                     {errors.last_name && touched.last_name ? (
                                                                                          <p className="contact-sales__input-error-message">
                                                                                               <BsFillExclamationTriangleFill className="contact-sales__input-error-icon" />
                                                                                               {errors.last_name}
                                                                                          </p>
                                                                                     ): null}
                                                                                </div>
                                                                           </div>
                                                                           <div className="contact-sales__form-input-flex">
                                                                                <div className="contact-sales__form-input-wrapper">
                                                                                     <p className="contact-sales__input-label">EMAIL</p>
                                                                                     <input 
                                                                                          type="email" 
                                                                                          className={`contact-sales__input ${errors.email && touched.email ? "contact-sales__input-error" : null}`} 
                                                                                          name="email"
                                                                                          value={values.email}
                                                                                          onChange={(e) => {
                                                                                               handleChange(e);
                                                                                               updateSalesData({...salesData, email: e.target.value});
                                                                                          }}
                                                                                          onBlur={handleBlur}
                                                                                          placeholder="maria@email.com"
                                                                                          autoComplete="off"
                                                                                     />
                                                                                     {errors.email && touched.email ? (
                                                                                          <p className="contact-sales__input-error-message">
                                                                                               <BsFillExclamationTriangleFill className="contact-sales__input-error-icon" />
                                                                                               {errors.email}
                                                                                          </p>
                                                                                     ): null}
                                                                                </div>
                                                                                <div className="contact-sales__form-input-wrapper">
                                                                                     <p className="contact-sales__input-label">PHONE NUMBER</p>
                                                                                     <input 
                                                                                          type="text" 
                                                                                          className={`contact-sales__input ${errors.phone_number && touched.phone_number ? "contact-sales__input-error" : null}`} 
                                                                                          name="phone_number"
                                                                                          value={values.phone_number}
                                                                                          onChange={(e) => {
                                                                                               handleChange(e);
                                                                                               updateSalesData({...salesData, phone_number: e.target.value});
                                                                                          }}
                                                                                          onBlur={handleBlur}
                                                                                          onKeyDown={(e) => {
                                                                                          const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
                                                                                               if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
                                                                                                    e.preventDefault();
                                                                                               }
                                                                                          }}
                                                                                          placeholder="+63"
                                                                                          autoComplete="off"
                                                                                          inputMode="numeric"
                                                                                          maxLength={11}
                                                                                     />
                                                                                     {errors.phone_number && touched.phone_number ? (
                                                                                          <p className="contact-sales__input-error-message">
                                                                                               <BsFillExclamationTriangleFill className="contact-sales__input-error-icon" />
                                                                                               {errors.phone_number}
                                                                                          </p>
                                                                                     ): null}
                                                                                </div>
                                                                           </div>
                                                                           <div className="contact-sales__form-input-flex">
                                                                                <div className="contact-sales__form-input-wrapper">
                                                                                     <p className="contact-sales__input-label">BUSINESS NAME</p>
                                                                                     <input 
                                                                                          type="text" 
                                                                                          className={`contact-sales__input ${errors.business_name && touched.business_name ? "contact-sales__input-error" : null}`}
                                                                                          name="business_name"
                                                                                          value={values.business_name}
                                                                                          onChange={(e) => {
                                                                                               handleChange(e);
                                                                                               updateSalesData({...salesData, business_name: e.target.value});
                                                                                          }} 
                                                                                          onBlur={handleBlur}
                                                                                          placeholder="ABC Corporation"
                                                                                          autoComplete="off"
                                                                                     />
                                                                                     {errors.business_name && touched.business_name ? (
                                                                                          <p className="contact-sales__input-error-message">
                                                                                               <BsFillExclamationTriangleFill className="contact-sales__input-error-icon" />
                                                                                               {errors.business_name}
                                                                                          </p>
                                                                                     ): null}
                                                                                </div>
                                                                           </div>
                                                                           <div className="contact-sales__submission-wrapper">
                                                                                <div className="contact-sales__privacy-policy">
                                                                                     <p className="contact-sales__privacy-policy-text">
                                                                                          Your personal data will be processed in accordance with our <a href="/privacy-policy" target="_blank" className="contact-sales__policy-link">Privacy Policy.</a>
                                                                                     </p>
                                                                                </div>
                                                                                <div className="contact-sales__button-wrapper">
                                                                                     <button 
                                                                                          type="submit"
                                                                                          className="contact-sales__submit-button"
                                                                                     >
                                                                                          SUBMIT
                                                                                     </button>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </Form>
                                                            )}
                                                       </Formik>
                                                  </div>
                                             </div>
                                        </div>
                                        <div className="contact-sales__support-container">
                                             <div className="contact-sales__contact-support-wrapper">
                                                  <div className="contact-sales__contact-support-top-statement-wrapper">
                                                       <p className="contact-sales__contact-support-top-statement">Looking for support instead?</p>
                                                  </div>
                                                  <div className="contact-sales__contact-support-heading-wrapper">
                                                       <p className="contact-sales__contact-support-heading">Contact our support team</p>
                                                       <p className="contact-sales__contact-support-subheading">We're here for you 24/7 via chat and email.</p>
                                                       <div className="contact-sales__contact-support-subheading-mobile-wrapper">
                                                            <p className="contact-sales__contact-support-subheading-mobile">We're here for you 24/7</p>
                                                            <p className="contact-sales__contact-support-subheading-mobile">via chat and email.</p>
                                                       </div>
                                                  </div>
                                                  <div className="contact-sales__contact-support-button-wrapper">
                                                       <button 
                                                            type="button"
                                                            className="contact-sales__contact-support-button"
                                                            onClick={RouteToContactSupport}
                                                       >
                                                            Contact Us
                                                       </button>
                                                  </div>
                                             </div>
                                        </div>
                                   </>
                              )}
                         </>
                    )}
               </div>
               {isSubmittingForm &&
                    <PageLoader />
               }
          </>
     )
}

export default ContactSales